import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { FindingsCounts } from "../../../types/Finding";
import { useNavigate } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { Filters, findingsDefaultFilters } from "../Findings";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Mixpanel } from "../../../shared/mixpanel";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { useRiskColors } from "../../../shared/findingsHelper";

type Props = {
  findingsCounts?: FindingsCounts;
  includeInfo?: boolean;
  hideLegendText?: boolean;
  isLoading?: boolean;
  selectedProduct?: number | "all";
  showOpenStatus?: boolean;
  filters?: Filters;
  size?: number;
  labelTextSize?: number;
  top?: string;
  labelNumberLineHeight?: string;
  isRiskColors?: boolean;
};

export const FindingDoughnutChart = (props: Props) => {
  const {
    findingsCounts,
    includeInfo = false,
    hideLegendText,
    isLoading,
    selectedProduct,
    showOpenStatus,
    filters,
    size,
    labelTextSize,
    top,
    labelNumberLineHeight,
    isRiskColors = true,
  } = props;
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { riskColors } = useRiskColors({ withInfo: true });

  const currentFilter = filters || findingsDefaultFilters;

  // Get counts for the legend
  // Ignore the risk filter to show all the counts if not selected
  let riskBreakdownFilter = { ...currentFilter };
  delete riskBreakdownFilter.overall_risk;

  const { data: findingsRiskCounts } =
    useApiFindingsCounts(riskBreakdownFilter);

  const getFindingsLink = (overall_risk: string) =>
    selectedProduct
      ? `/findings?filters=${objectToBase64({
          ...currentFilter,
          overall_risk,
          product: selectedProduct,
        })}`
      : `/findings?filters=${objectToBase64({
          ...currentFilter,
          overall_risk,
        })}`;

  const getLegendLabelColor = (overallRisk: number) =>
    currentFilter.overall_risk === `${overallRisk}`
      ? theme.black800
      : theme.textSub;

  const backgroundColor = isRiskColors
    ? [riskColors.critical, riskColors.high, riskColors.medium, riskColors.low]
    : [theme.blue, theme.blue500, theme.blue300, theme.blue100];

  var labels = ["Critical", "High", "Medium", "Low"];
  var datasets = findingsRiskCounts
    ? [
        {
          data: [
            findingsRiskCounts.open_criticals,
            findingsRiskCounts.open_highs,
            findingsRiskCounts.open_mediums,
            findingsRiskCounts.open_lows,
          ],
          backgroundColor,
        },
      ]
    : [{ data: [], backgroundColor: [] }];

  if (includeInfo) {
    labels.push("Info");
    datasets[0].data.push(findingsCounts?.open_infos || 0);
    datasets[0].backgroundColor.push(
      isRiskColors ? riskColors.info || "" : theme.blue50
    );
  }

  return (
    <Flex justify="center" align="center" w100 h100 gap="24px">
      <Flex w100 justify="center">
        <DoughnutChart
          labelNumber={findingsCounts?.total || 0}
          labelText={showOpenStatus ? "Open Issues" : "Total"}
          isLoadingData={isLoading}
          labels={labels}
          datasets={datasets}
          size={size || 100}
          labelNumberLineHeight={
            labelNumberLineHeight
              ? labelNumberLineHeight
              : showOpenStatus
                ? "35px"
                : "45px"
          }
          labelNumberSize={
            findingsCounts?.total && findingsCounts.total > 1000 ? 18 : 24
          }
          labelTextSize={labelTextSize}
          top={top}
        />
      </Flex>
      <Flex column w100>
        <LegendItem
          color={backgroundColor[0]}
          labelColor={getLegendLabelColor(4)}
          labelSize={12}
          label="Critical"
          value={findingsRiskCounts?.open_criticals}
          hideLegendText={hideLegendText}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "Critical" });
            navigate(getFindingsLink("4"));
          }}
        />
        <LegendItem
          color={backgroundColor[1]}
          labelSize={12}
          label="High"
          labelColor={getLegendLabelColor(3)}
          value={findingsRiskCounts?.open_highs}
          hideLegendText={hideLegendText}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "High" });
            navigate(getFindingsLink("3"));
          }}
        />
        <LegendItem
          color={backgroundColor[2]}
          labelColor={getLegendLabelColor(2)}
          labelSize={12}
          label="Medium"
          value={findingsRiskCounts?.open_mediums}
          hideLegendText={hideLegendText}
          onClick={() => {
            navigate(getFindingsLink("2"));
          }}
        />
        <LegendItem
          color={backgroundColor[3]}
          labelColor={getLegendLabelColor(1)}
          labelSize={12}
          label="Low"
          value={findingsRiskCounts?.open_lows}
          hideLegendText={hideLegendText}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "Low" });
            navigate(getFindingsLink("1"));
          }}
        />
        {includeInfo && (
          <LegendItem
            color={backgroundColor[4]}
            labelColor={getLegendLabelColor(0)}
            labelSize={12}
            label="Info"
            value={findingsRiskCounts?.open_infos || 0}
            hideLegendText={hideLegendText}
            onClick={() => {
              Mixpanel.track("Findings Overview", { severity: "Info" });
              navigate(getFindingsLink("0"));
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
