import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  getDateTimeDaysBeforeNow,
  toBase64AssetsView,
} from "../../../shared/helper";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { Mixpanel } from "../../../shared/mixpanel";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_BIG_DESKTOP_WIDTH } from "../../../shared/consts";
import { AssetOverviewCounter } from "../topBlocks/dashboardOverview/AssetOverviewCounter";
import { useApiAssetsOverview } from "../../insights/findingsTrendsGraph/findingsTrendsContext";
import { TrendBadge } from "./TrendBadge";
import { SingleValue } from "react-select";

type Props = {
  selectedProduct: number | "all";
};

export const AssetsOverviewV2 = (props: Props) => {
  const { selectedProduct } = props;
  const screenWidth = useScreenWidth();
  const theme = useContext(ThemeContext);
  const timeOptions: Option[] = [
    { label: "Last 24 Hours", value: 1 },
    { label: "Last 7 Days", value: 7 },
    { label: "Last 30 Days", value: 30 },
    { label: "Last 6 Months", value: 180 },
    { label: "Last Year", value: 365 },
  ];

  const [assetsCountsTimeframe, setAssetsCountsTimeframe] = useState(30);
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    SingleValue<Option>
  >({ label: "Last 30 Days", value: 30 });

  const getAssetsCountParams = () => {
    var params: any = {};
    if (selectedProduct !== "all") params.product = selectedProduct;
    if (assetsCountsTimeframe) params.timeframe = assetsCountsTimeframe;
    return params;
  };

  const { data: assetsOverview, isFetching } = useApiAssetsOverview(
    getAssetsCountParams()
  );

  const oldTotalAssets: number = !!assetsOverview?.timeframe
    ? Object.values(assetsOverview.timeframe).reduce((a, b) => a + b, 0)
    : 0;

  const currentTotalAssets: number = !!assetsOverview?.current
    ? Object.values(assetsOverview.current).reduce((a, b) => a + b, 0)
    : 0;

  const currentTotalVulnerableAssets: number = !!assetsOverview?.timeframe
    ? assetsOverview.current.critical +
      assetsOverview.current.high +
      assetsOverview.current.medium +
      assetsOverview.current.low
    : 0;

  const oldTotalVulnerableAssets: number = !!assetsOverview?.timeframe
    ? assetsOverview.timeframe.critical +
      assetsOverview.timeframe.high +
      assetsOverview.timeframe.medium +
      assetsOverview.timeframe.low
    : 0;

  return (
    <>
      <Flex column justify="between" gap="24px" h100 w100>
        <Flex justify="between" align="center" w100>
          <Flex className="text-truncate" align="center" gap="8px">
            <HeaderSecondary className="text-truncate" title="Assets Overview">
              Assets Overview
            </HeaderSecondary>
          </Flex>
          <Flex
            style={{
              overflow: "auto",
              maxWidth: "120px",
              flexShrink: 0,
            }}
          >
            <Dropdown
              onChange={(option) => {
                Mixpanel.track(
                  "Dashboard - Assets Overview - Timeframe selection",
                  { timeSelected: option?.label || "" }
                );

                setAssetsCountsTimeframe(
                  parseInt(option?.value.toString() || "")
                );
                setSelectedTimeframe(option);
              }}
              options={timeOptions}
              value={selectedTimeframe}
              isMenuPositionFixed
            />
          </Flex>
        </Flex>
        <Flex gap="16px" justify="between" align="end" h100 w100>
          <Flex column w100>
            <AssetOverviewCounter
              title="Total Assets"
              count={`${currentTotalAssets}`}
              isLoading={!!isFetching && !assetsOverview}
              linkTo={`/assets?view=${toBase64AssetsView({
                name: "",
                filters: [
                  {
                    column: "created_at",
                    value: getDateTimeDaysBeforeNow(
                      assetsCountsTimeframe
                    ).toISOString(),
                    condition: "after",
                    order: 0,
                    next_condition: "and",
                  },
                ],
              })}`}
            />
            <TrendBadge
              oldValue={oldTotalAssets}
              newValue={currentTotalAssets}
              timeframeLabel={selectedTimeframe?.label || ""}
            />
          </Flex>
          <SeparatorVertical style={{ height: "100px" }} />
          <Flex column w100>
            <AssetOverviewCounter
              title="Total Vulnerable Assets"
              count={`${currentTotalVulnerableAssets}`}
              isLoading={!!isFetching && !assetsOverview}
              linkTo={`/assets?view=${toBase64AssetsView({
                name: "",
                filters: [
                  {
                    column: "risk_score",
                    value: "0",
                    condition: "gt",
                    order: 0,
                    next_condition: "and",
                  },
                ],
              })}`}
            />
            <TrendBadge
              oldValue={oldTotalVulnerableAssets}
              newValue={currentTotalVulnerableAssets}
              timeframeLabel={selectedTimeframe?.label || ""}
            />
          </Flex>
          <SeparatorVertical style={{ height: "100px" }} />
          <Flex column w100>
            <AssetOverviewCounter
              title="Protection Coverage"
              titleElement={
                <Flex align="center" gap="4px">
                  <LabelRegular
                    className={
                      screenWidth < SCREEN_BIG_DESKTOP_WIDTH
                        ? "text-truncate"
                        : ""
                    }
                    title="Protection Coverage"
                    color={theme.textSecondary}
                  >
                    Protection Coverage
                  </LabelRegular>
                  <Tooltip
                    placement="bottom"
                    content={
                      <LabelRegular
                        style={{
                          maxWidth: "200px",
                          textTransform: "none",
                        }}
                      >
                        <strong>Protection Coverage</strong> measures security
                        measures for an organization's assets. <br />
                        It considers three factors - Web Application Firewall
                        (WAF), <br />
                        Penetration Testing, and Active Security Monitoring
                        (ASM). <br />
                        The score is based on whether these factors are present
                        or not, with equal weight for each.
                      </LabelRegular>
                    }
                  >
                    <Icon name="info" size={16} color={theme.black600} />
                  </Tooltip>
                </Flex>
              }
              count={`${assetsOverview?.current_coverage || 0}%`}
              isLoading={!!isFetching && !assetsOverview}
            />
            <TrendBadge
              newValue={assetsOverview?.current_coverage || 0}
              oldValue={assetsOverview?.timeframe_coverage || 0}
              timeframeLabel={selectedTimeframe?.label || ""}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
