import { Box } from "../../../components/elements/box/Box";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { OPEN_STATUSES } from "../../../types/Finding";
import { FindingDoughnutChart } from "../../findings/findingsCharts/FindingDoughnutChart";

export const DashboardFindingsOverview = ({
  selectedProduct,
}: {
  selectedProduct?: number | "all";
}) => {
  const filters =
    !!selectedProduct && selectedProduct !== "all"
      ? { status: OPEN_STATUSES, products: selectedProduct }
      : { status: OPEN_STATUSES };

  const { data: findingsCounts, isFetching } = useApiFindingsCounts(filters);

  return (
    <Box style={{ width: "100%" }}>
      <Flex column h100 gap="32px">
        <HeaderSecondary>Findings Overview</HeaderSecondary>
        <Flex column h100 justify="center">
          <FindingDoughnutChart
            includeInfo
            showOpenStatus
            size={140}
            top={"35px"}
            findingsCounts={findingsCounts}
            isLoading={isFetching && !findingsCounts?.open}
            selectedProduct={selectedProduct}
            filters={filters}
            labelTextSize={14}
            labelNumberLineHeight="48px"
          />
        </Flex>
      </Flex>
    </Box>
  );
};
