import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";

type Props = {
  score: number;
};

export const SecurityScorePieChart = ({ score }: Props) => {
  const theme = useContext(ThemeContext);

  const getBackgroundColor = (score: number) => {
    if (score < 30) return theme.redPrimary;
    if (score >= 30 && score < 70) return theme.yellowPrimary;
    return theme.greenPrimary;
  };

  return (
    <Flex h100 w100 align="center" justify="around" gap="24px">
      <DoughnutChart
        isLoadingData={score === undefined}
        labels={["Security Score"]}
        datasets={[
          {
            data: [score, 100 - score],
            backgroundColor: [getBackgroundColor(score), theme.black500],
          },
        ]}
        size={100}
        top={"25px"}
        labelNumber={score}
        labelText=""
        labelSuffix="%"
        labelNumberSize={24}
      />
      <Flex column>
        <BodyBold>Security Score Calculation</BodyBold>
        <BodyRegular>
          Weighted calculation based on findings severity and the importance of
          their affected assets.
        </BodyRegular>
        <BodyRegular>
          Fixing issues on high-priority assets will increase your security
          score the most.
        </BodyRegular>
      </Flex>
    </Flex>
  );
};
