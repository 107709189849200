import { useContext, useState } from "react";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { objectsToOptions } from "../../../shared/formUtils";
import { Box } from "../../../components/elements/box/Box";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  useApiBenchmarkIndustryAvg,
  useApiSecurityBenchmark,
  useApiSecurityBenchmarkOverTime,
} from "../../../hooks/queries/findingContext";
import { BenchmarkMeter } from "./BenchmarkMeter";
import { Mixpanel } from "../../../shared/mixpanel";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { BenchmarkOverTime, isScoreMetric } from "./BenchmarkOverTime";
import {
  findingsTimeOptions,
  Timeframe,
} from "../../findings/filters/Timeframe";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { ThemeContext } from "styled-components";

export const titleMap: { [key: string]: string } = {
  risk_score: "Risk Score",
  coverage_score: "Protection Coverage",
  mttr: "Mean Time to Remediate",
  open_findings_percent: "Percentage of Open Findings",
  open_avg_age: "Average Finding Age",
};

const defaultProductOption = { label: "All Products", value: "all" };

export type OverTimeMetric =
  | "risk_score"
  | "coverage_score"
  | "mttr"
  | "open_findings_percent"
  | "open_avg_age";

export const RiskScoreBenchmark = () => {
  const isSuperuser = useIsSuperuser();
  const { data: products } = useApiProducts();
  const theme = useContext(ThemeContext);

  const productsOptions = !!products?.length
    ? [defaultProductOption, ...objectsToOptions(products)]
    : [];

  const [selectedProduct, setSelectedProduct] =
    useState<SingleValue<Option>>(defaultProductOption);

  const prodFilter =
    selectedProduct?.value !== "all" ? { product: selectedProduct?.value } : {};

  const [overTimeMetric, setOverTimeMetric] = useState<OverTimeMetric | "">("");

  const [timeframe, setTimeframe] = useState<SingleValue<Option>>(
    findingsTimeOptions[5]
  );

  const { data, isLoading, isRefetching } = useApiSecurityBenchmark({
    ...prodFilter,
  });

  const { data: overTimeData, isFetching: isRefetchingOverTimeData } =
    useApiSecurityBenchmarkOverTime({
      ...prodFilter,
      timeframe: timeframe?.value,
      over_time_metric: overTimeMetric,
    });

  const { data: industryAvgData } = useApiBenchmarkIndustryAvg();

  const handleProductChange = (opt: SingleValue<Option>) => {
    setSelectedProduct(opt);
    Mixpanel.track("Security Benchmark - filter by product");
  };

  return (
    <Box
      // id="benchmark"
      style={{
        padding: "24px",
        minWidth: "600px",
        width: "100%",
        position: "relative",
      }}
    >
      <Flex w100 column>
        <div id="benchmark" style={{ position: "absolute", top: "-524px" }} />
        {!overTimeMetric || !isSuperuser ? (
          <>
            <Flex justify="between">
              <HeaderSecondary>Security Benchmark</HeaderSecondary>
              <Dropdown
                variant="outline"
                value={selectedProduct}
                options={productsOptions}
                onChange={handleProductChange}
                queryStatus={isLoading || isRefetching ? "loading" : undefined}
              />
            </Flex>

            {Object.keys(titleMap).map((metric) => (
              <BenchmarkMeter
                key={metric}
                label={titleMap[metric]}
                isDays={["mttr", "open_avg_age"].includes(metric)}
                isPercent={!["mttr", "open_avg_age"].includes(metric)}
                isHigherBetter={["coverage_score"].includes(metric)}
                value={data?.[metric as OverTimeMetric]?.current || 0}
                optimum={
                  isScoreMetric(metric)
                    ? ((industryAvgData?.[metric as OverTimeMetric] ||
                        0) as number)
                    : // @ts-ignore
                      ((industryAvgData?.[metric as OverTimeMetric].total ||
                        0) as number)
                }
                onClick={() => {
                  setOverTimeMetric(metric as OverTimeMetric);
                  Mixpanel.track(
                    `Security Benchmark - view ${metric} over time`
                  );
                }}
              />
            ))}
          </>
        ) : overTimeMetric ? (
          <Flex column w100 gap="24px">
            <Flex gap="8px" align="center">
              <IconButton
                iconName="chevronLeft"
                onClick={() => setOverTimeMetric("")}
              />
              <Flex w100 justify="between">
                <Flex align="center" gap="32px">
                  <HeaderSecondary style={{ textTransform: "capitalize" }}>
                    {titleMap[overTimeMetric]}
                  </HeaderSecondary>
                  <BodyRegular color={theme.textSub}>
                    {["coverage_score"].includes(overTimeMetric)
                      ? "Higher "
                      : "Lower "}
                    is better
                  </BodyRegular>
                </Flex>
                <Timeframe
                  filters={{}}
                  handleCustomDate={() => {}}
                  handleTimeframeChange={(opt) => {
                    Mixpanel.track(
                      `Security Benchmark - set timeframe tp ${timeframe?.label}`
                    );
                    setTimeframe(opt);
                  }}
                  timeframe={timeframe}
                />
              </Flex>
            </Flex>
            <BenchmarkOverTime
              timeframe={timeframe}
              benchmarkData={data}
              overTimeData={overTimeData}
              industryAvgData={industryAvgData}
              isFetching={isRefetchingOverTimeData}
              selectedMetric={overTimeMetric}
            />
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};
