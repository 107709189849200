import { ThemeContext } from "styled-components";
import { KPI } from "../../../components/composed/kpi/KPI";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderSecondary,
  HeaderSub,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_BIG_DESKTOP_WIDTH,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
} from "../../../shared/consts";
import { useContext } from "react";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { SecurityScorePieChart } from "./SecurityScorePieChart";
import { useApiSecurityScoreExtended } from "../../insights/findingsTrendsGraph/findingsTrendsContext";
import { useNavigate } from "react-router";
import { Mixpanel } from "../../../shared/mixpanel";
import { Icon } from "../../../components/elements/icon/Icon";
import { UnprotectedTooltipContent } from "../../../shared/tooltipTexts";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";

type Props = {
  selectedProduct: number | "all";
};

export const SecurityScore = (props: Props) => {
  const { selectedProduct } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth < SCREEN_BIG_DESKTOP_WIDTH - 100;
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;
  const navigate = useNavigate();

  const params =
    selectedProduct && selectedProduct !== "all"
      ? { product_id: selectedProduct }
      : {};
  const { data: securityScoreExtended } = useApiSecurityScoreExtended(params);

  return (
    <Box
      style={{
        height: isSmallScreen ? "100%" : "470px",
        width: isSmallScreen ? "100%" : "440px",
      }}
    >
      <Flex column={!isSmallScreen || isLaptop} justify="between" gap="32px">
        <Flex column justify="between" gap="16px" w100>
          <Flex align="center" justify="between">
            <HeaderSecondary>Security Score</HeaderSecondary>
            <Flex>
              <TextButton
                label="Go to Insights Page"
                onClick={() => {
                  Mixpanel.track(
                    "Go to Insights Page from Dashboard Security Score"
                  );
                  navigate("/insights#benchmark");
                }}
              />
            </Flex>
          </Flex>
          <SecurityScorePieChart
            score={securityScoreExtended?.security_score || 0}
          />
        </Flex>

        <Flex column style={{ minWidth: "400px" }} gap="0px">
          <HeaderSub color={theme.textSub}>Required Attention </HeaderSub>
          <Flex align="center" justify="between" w100>
            <HeaderSubBold>Highly Vulnerable Assets</HeaderSubBold>
            <KPI
              color={theme.redPrimary}
              count={securityScoreExtended?.highly_vulnerable_assets}
              style={{ fontSize: "20px" }}
            />
          </Flex>
          <Flex align="center" justify="between" w100>
            <Flex align="center" gap="8px">
              <HeaderSubBold>Unprotected Asses</HeaderSubBold>

              <Tooltip content={UnprotectedTooltipContent}>
                <Icon name="info" color={theme.black600} size={16} />
              </Tooltip>
            </Flex>

            <KPI
              color={theme.redPrimary}
              count={securityScoreExtended?.asm_disabled_assets}
              style={{ fontSize: "20px" }}
            />
          </Flex>
          <Flex align="center" justify="between" w100>
            <HeaderSubBold>Unassigned Findings</HeaderSubBold>
            <KPI
              color={theme.redPrimary}
              count={securityScoreExtended?.unassigned_findings}
              style={{ fontSize: "20px" }}
            />
          </Flex>
          <Flex align="center" justify="between" w100>
            <HeaderSubBold>Urgent Findings</HeaderSubBold>
            <KPI
              color={theme.redPrimary}
              count={securityScoreExtended?.urgent_findings}
              style={{ fontSize: "20px" }}
            />
          </Flex>
          <Flex align="center" justify="between" w100>
            <HeaderSubBold>Widespread Findings</HeaderSubBold>
            <KPI
              color={theme.redPrimary}
              count={securityScoreExtended?.widespread_findings}
              style={{ fontSize: "20px" }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
